import axios from "axios";

const axiosInstance = axios.create({
  baseURL: "http://localhost:8081",
  headers: {
    "Content-Type": "application/json",
  },
});

axiosInstance.interceptors.request.use(
  function (config) {
    // Retrieve the token from session storage
    console.log("am intrat in interceptor");
    const token = sessionStorage.getItem("token");
    console.log(token);

    // If a token exists, add it to the Authorization header
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
      console.log("am pus tokenul in header");
      console.log(config.headers);
    }

    if (config.data) {
      config.data = transformObjectKeys(config.data, camelToKebabCase);
    }

    return config;
  },
  function (error) {
    // Handle any errors
    return Promise.reject(error);
  },
);

function transformObjectKeys(obj, transformKey) {
  if (obj !== null && typeof obj === "object") {
    if (Array.isArray(obj)) {
      return obj.map((item) => transformObjectKeys(item, transformKey));
    } else {
      return Object.keys(obj).reduce((acc, key) => {
        const kebabKey = transformKey(key);
        acc[kebabKey] = transformObjectKeys(obj[key], transformKey);
        return acc;
      }, {});
    }
  }
  return obj;
}

function camelToKebabCase(str) {
  return str.replace(/([a-z0-9])([A-Z])/g, "$1-$2").toLowerCase();
}

export default axiosInstance;
