import { DarkThemeToggle } from "flowbite-react";
import { NavbarComponent } from "./components/Navbar";
import { BrowserRouter, useRoutes, useNavigate } from "react-router-dom";
import { Login } from "./components/Login";
import { Projects } from "./components/Projects";
import { Environment } from "./components/Environment";
import { FAQ } from "./components/FAQ";
import { FooterComponent } from "./components/Footer";
import { useEffect } from "react";
import axiosInstance from "./utils/axiosInstance";
import axios from "axios";

const AppRoutes = () => {
  const routes = useRoutes([
    { path: "/login", element: <Login /> },
    {
      path: "/projects",
      element: <Projects />,
    },
    {
      path: "/environment",
      element: <Environment />,
    },
    {
      path: "/faq",
      element: <FAQ />,
    },
  ]);

  return routes;
};

function App() {
  return (
    <>
      {/* <main className="flex min-h-screen items-center justify-center gap-2 dark:bg-gray-800"> */}
      <BrowserRouter>
        <NavbarComponent />
        <section className="relative min-h-screen  bg-transparent lg:pb-[90px] lg:pt-[120px]">
          <ProtectedComponent>
            <AppRoutes />
            <div className="absolute bottom-0 right-0 -z-50 h-full w-full bg-gray-50 dark:bg-gray-900">
              <svg
                width="1440"
                height=""
                viewBox="0 0 1440 886"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  opacity="0.5"
                  d="M193.307 -273.321L1480.87 1014.24L1121.85 1373.26C1121.85 1373.26 731.745 983.231 478.513 729.927C225.976 477.317 -165.714 85.6993 -165.714 85.6993L193.307 -273.321Z"
                  fill="url(#paint0_linear)"
                />
                <defs>
                  <linearGradient
                    id="paint0_linear"
                    x1="1308.65"
                    y1="1142.58"
                    x2="602.827"
                    y2="-418.681"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stop-color="#3056D3" stop-opacity="0.36" />
                    <stop offset="1" stop-color="#F5F2FD" stop-opacity="0" />
                    <stop
                      offset="1"
                      stop-color="#F5F2FD"
                      stop-opacity="0.096144"
                    />
                  </linearGradient>
                </defs>
              </svg>
            </div>
          </ProtectedComponent>
        </section>
        {/* <h1 className="text-2xl dark:text-white">
          Flowbite React + Create React App
        </h1>
        <DarkThemeToggle /> */}
      </BrowserRouter>
      {/* </main> */}
      <FooterComponent />
    </>
  );
}

async function checkToken() {
  try {
    const res = await axiosInstance.post("/postman/sessions/check-token", {});
    if (res.status === 200) {
      return true;
    }
    return false;
  } catch (error) {
    return false;
  }
}

function ProtectedComponent(props: any) {
  const navigate = useNavigate();
  useEffect(() => {
    const token = sessionStorage.getItem("token");
    if (!token) {
      navigate("/login");
    }
    const res = axiosInstance
      .post("/postman/sessions/check-token", {})
      .then()
      .catch(() => {
        sessionStorage.removeItem("token");
        navigate("/login");
      });
  }, [navigate]);
  return props.children;
}

export default App;
