import { Button, Navbar } from "flowbite-react";
import { DarkThemeToggle, Avatar } from "flowbite-react";
import { MdLogin } from "react-icons/md";
import { useNavigate } from "react-router-dom";

export function NavbarComponent() {
  const navigate = useNavigate();
  return (
    <Navbar fluid rounded>
      <Navbar.Brand>
        <img
          src="../truid-logo.jpg"
          className="mr-3 h-6 sm:h-9"
          alt="Flowbite React Logo"
        />
        <span className="self-center whitespace-nowrap text-xl font-semibold dark:text-white">
          Postman Portal
        </span>
      </Navbar.Brand>
      <div className="flex md:order-2">
        <DarkThemeToggle />
        {/* <Button>Get started</Button> */}
        {/* <a  className="flex items-center"> */}
        <button
          onClick={() => navigate("/login")}
          className="flex items-center rounded bg-transparent  px-4 py-2 font-bold text-black dark:text-white"
        >
          <MdLogin />
        </button>
        {/* </a> */}
        <Navbar.Toggle />
      </div>
      <Navbar.Collapse>
        <Navbar.Link href="#" active>
          Home
        </Navbar.Link>
        <Navbar.Link onClick={() => navigate("/projects")}>
          Projects
        </Navbar.Link>
        <Navbar.Link onClick={() => navigate("/environment")}>
          Environment
        </Navbar.Link>
        <Navbar.Link onClick={() => navigate("/FAQ")}>FAQ</Navbar.Link>
      </Navbar.Collapse>
    </Navbar>
  );
}
